import * as React from 'react';
import FaqItem from './FaqItem';
import { Container } from '@mui/material';

export default function FaqList() {
const faqData = [
    {
        question: "Transport do Czarnogóry",
        answer: ["Czarnogóra ma dwa lotniska: główne w Podgoricy oraz mniejsze w miejscowości Tivat. Podróż z Podgoricy do Zatoki Kotorskiej (Perast) trwa około 2 godziny, a z Tivatu około 30 minut. Bezpośrednie loty do Podgoricy są dostępne z Krakowa, Katowic i Warszawy. Do Tivatu nie ma lotów bezpośrednich, ale można tam dotrzeć na przykład liniami Austrian Airlines z Krakowa z przesiadką w Wiedniu."]
    },
    {
        question: "Nocleg",
        answer: ["Niestety, nie jesteśmy w stanie zapewnić noclegu dla wszystkich gości w jednym miejscu. Nad Zatoką Kotorską nie ma dużych hoteli, a dostępne są głównie apartamenty i butikowe hotele z niewielką liczbą pokoi. Oznacza to, że musielibyśmy rozlokować gości w różnych miejscach. Dlatego kwestię noclegów pozostawiamy w Waszych rękach, abyście sami mogli wybrać obiekt, w którym chcecie się zatrzymać, oraz ewentualnie zdecydować, z kim chcecie go dzielić. Koszty zakwaterowania pokrywane przez Was możecie sobie, prosimy, zrekompensować w kopertach. :)"]
    },
    {
        question: "Potwierdzenie przybycia",
        answer: [
            "Bardzo prosimy o jak najszybsze potwierdzenie przybycia, najlepiej do końca marca 2025 roku. Jeśli z jakiegoś powodu potrzebujecie więcej czasu na podjęcie decyzji, dajcie nam znać.", 
            "Kontaktować się z nami można w dowolny sposób. :)"
        ]
    },
    {
        question: "W dniu ślubu",
        answer: ["Ślub odbędzie się na wyspie Matki Boskiej na Skale, zapewniamy transport wodny z miejscowości Perast (dokładna godzina i miejsce zbiórki zostaną podane w późniejszym terminie). Po ceremonii łódka zabierze nas bezpośrednio do restauracji Bocasa. Po zakończeniu przyjęcia do Waszej dyspozycji będzie kierowca z busikiem, który odwiezie Was do hoteli znajdujących się w obrębie Zatoki Kotorskiej."],
        iframeSrc: "https://www.google.com/maps/d/embed?mid=1xmJZc7RJ4gHB5ZotE2dyIZB7-3hL-S4&hl=en&ehbc=2E312F"
    },
    {
        question: "Ślub",
        answer: ["Godzina ślubu zostanie podana w późniejszym terminie. Ceremonia odbędzie się w kościele, a poprowadzi ją polskojęzyczny ksiądz. Widoki z wysepki, na której znajduje się kościół, są przepiękne, więc serdecznie zachęcamy do odwiedzenia tego miejsca, nawet jeśli nie planujecie uczestniczyć w ceremonii."]
    },
    {
        question: "Wesele",
        answer: ["Więcej szczegółów wkrótce ;)"]
    },
    {
        question: "Jedzenie/napoje",
        answer: [
            "Jeśli stosujecie dietę wegetariańską, wegańską, bezglutenową lub inną, prosimy o poinformowanie nas o tym przy potwierdzaniu przybycia."
        ]
    },
    {
        question: "Prezenty",
        answer: ["Jeśli planujecie obdarować nas prezentem, prosimy, aby nie był zbyt duży ze względu na ograniczenia bagażowe. W ramach podróży poślubnej zamierzamy spędzić trochę czasu na Bałkanach, dlatego również prosimy, aby ewentualne podarunki pieniężne były w euro lub przelane na konto bankowe (w PLN). Dane do przelewu podamy w późniejszym terminie."]
    },
    {
        question: "Ubiór & inne",
        answer: [
            "Koniec sierpnia może być bardzo gorący, więc warto wziąć to pod uwagę, kompletując strój. Wysepka, na której odbędzie się ślub, oraz wybrzeże, przy którym znajduje się restauracja, są dość kamieniste, dlatego polecamy dobrze przemyśleć wybór obuwia.", 
            "Przyda się również butelka wody na czas, zanim dotrzemy do restauracji.", 
            "W odległości około 2 minut od restauracji znajduje się tramwaj wodny, który kursuje na drugą stronę zatoki mniej więcej co 15 minut"
        ],
        iframeSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4950.316924472996!2d18.676204617038362!3d42.46016286341684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134c312e51d8d0e9%3A0xa11e0085295a1cad!2sFerry%20Kamenari!5e0!3m2!1sen!2spl!4v1734780732708!5m2!1sen!2spl"

    }
];

return (
    <Container sx={{ paddingLeft: { xs: 0, lg: 4 }, paddingRight: { xs: 0, lg: 4 } }}>
        {faqData.map((faq, index) => (
            <FaqItem key={index} question={faq.question} answer={faq.answer} iframeSrc={faq.iframeSrc} />
        ))}
    </Container>
);
}