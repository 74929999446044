import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

const FaqItem = ({ question, answer, iframeSrc }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h4" fontFamily={"Carattere, cursive"}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {answer.map((paragraph, index) => (
          <Typography p={1} key={index} variant="h5" fontFamily={"Carattere"}>
            {paragraph}
          </Typography>
        ))}
        
        {iframeSrc && (
          <iframe
            width="100%"
            height="315"
            src={iframeSrc}
            title="Google Maps"
            allowFullScreen
          ></iframe>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqItem;
